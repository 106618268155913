import './index.scss';
import Navbar from '../../components/Navbar';


const Home = () => {
    return (
    <div className='homepage-background'>
        <Navbar />
        
    </div>
)}

export default Home;